<template>
  <v-card>
    <v-card-title>Graphql Testing</v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>description</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="obj in applications"
            :key="obj.id"
          >
            <td>{{ obj.applicationid }}</td>
            <td>{{ obj.name }}</td>
            <td>{{ obj.description }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        outlined
        @click="debug"
        color="#b31616"
      >Test</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
export default {
  name: "GqlTest",
  data: () => ({
    apiPath: "http://localhost:4000/",
  }),
  apollo: {
    applications: {
      query: gql`
        query {
          applications {
            applicationid
            name
            description
          }
        }
      `,
      pollInterval: 300,
    },
  },
  mounted() {
    const observer = this.$apollo.subscribe({
      query: gql`subscribe {
        applications {
          mutation
          payload {
            applicationid
            name
            description
          }
        }
      }`,
    });
  },
  methods: {
    debug: async function () {},
  },
};
</script>
<style>
</style>